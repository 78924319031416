<template>
    <div>
      <v-layout wrap justify-center pa-4 pa-lg-10>
        <v-flex xs12 text-center py-5>
          <span
            class="bold"
            style="
              border-bottom: 5px solid #ff8533;
              font-size: 30px;
              padding-left: 20px;
              padding-right: 20px;
            "
            >Student List</span
          >
        </v-flex>
  
        <v-flex lg12 v-if="this.list === '2020-2022 First Year'">
        <v-layout wrap justify-center>
        <v-flex xs12 lg9 class="bold" style="font-size:20px;color:#2c3e50" pt-lg-6>
  2020-2022 First Year
  </v-flex>
    <v-flex x12 lg9>
          <table>
            <tr>
              <th>S.No</th>
              <th>Adm.NO</th>
              <th>Name</th>
              <th>Dob</th>
            </tr>
            <tr>
              <td>1</td>
              <td>2096</td>
              <td>Abilin TA</td>
              <td>08/03/2002</td>
            </tr>
            <tr>
              <td>2</td>
              <td>2097</td>
              <td>Agnus carolin paul</td>
              <td>29/03/1998</td>
            </tr>
            <tr>
              <td>3</td>
              <td>2098</td>
              <td>Ashna maria furtel</td>
              <td>10/03/1999</td>
            </tr>
            <tr>
              <td>4</td>
              <td>2099</td>
              <td>Tresa Joseph</td>
              <td>09/09/1998</td>
            </tr>
             <tr>
              <td>5</td>
              <td>2100</td>
              <td>Athul Joy</td>
              <td>22/12/2002</td>
            </tr>
             <tr>
              <td>6</td>
              <td>2101</td>
              <td>Aparna ES</td>
              <td>07/06J2002</td>
            </tr>
             <tr>
              <td>7</td>
              <td>2102</td>
              <td>Shinumol francis</td>
              <td>12/08/1999</td>
            </tr>
             <tr>
              <td>8</td>
              <td>2103</td>
              <td>Georgekutty Mathew </td>
              <td>17/04/2003</td>
            </tr>
             <tr>
              <td>9</td>
              <td>2104</td>
              <td>Abi Mathew A</td>
              <td>21/05/1996</td>
            </tr>
             <tr>
              <td>10</td>
              <td>2105</td>
              <td>Noufia Nazar</td>
              <td>17/12/1998</td>
            </tr>
             <tr>
              <td>11</td>
              <td>2106</td>
              <td>Jinumol T john</td>
              <td>30/01/1998</td>
            </tr>
             <tr>
              <td>12</td>
              <td>2107</td>
              <td>Saji joseph</td>
              <td>14/10/1998  </td>
            </tr>
             <tr>
              <td>13</td>
              <td>2108</td>
              <td>Robin Augustine Thomas</td>
              <td>06/02/2000 </td>
            </tr>
             <tr>
              <td>14</td>
              <td>2109</td>
              <td>Tony Varky</td>
              <td>05/04/2000</td>
            </tr>
             <tr>
              <td>15</td>
              <td>2110</td>
              <td>Shilpa mary S</td>
              <td>02/09/1998</td>
            </tr>
             <tr>
              <td>16</td>
              <td>2111</td>
              <td>Mahima Mathew</td>
              <td>05/06/1999</td>
            </tr>
             <tr>
              <td>17</td>
              <td>2112</td>
              <td>Nithin Tom</td>
              <td>02/06/1999</td>
            </tr>
             <tr>
              <td>18</td>
              <td>2113 </td>
              <td>Tom Jose</td>
              <td>17/06/1998</td>
            </tr>
             <tr>
              <td>19</td>
              <td>2114</td>
              <td>Sneha Thomas</td>
              <td>19/01/1998</td>
            </tr>
              <tr>
              <td>20</td>
              <td>2115</td>
              <td>Saijo K Thomas</td>
              <td>15/07/1992</td>
            </tr>
             <tr>
              <td>21</td>
              <td>2116</td>
              <td>Abin Thomas</td>
              <td>20/01/2000</td>
            </tr>
            <tr>
              <td>22</td>
              <td>2117</td>
              <td>Elsa mol Joseph</td>
              <td>25/02/2003</td>
            </tr>
             <tr>
              <td>23</td>
              <td>2118</td>
              <td>Sandra rose Thomas	</td>
              <td>26/07/2002</td>
            </tr>
             <tr>
              <td>24</td>
              <td>2119</td>
              <td>Treema Paul	</td>
              <td>28/03/1999</td>
            </tr>
            <tr>
              <td>25</td>
              <td>2120</td>
              <td>Prince Mathew</td>
              <td>04/10/1996</td>
            </tr>
            <tr>
              <td>26</td>
              <td>2121</td>
              <td>Kochurani Shaji</td>
              <td>24/05/1999</td>
            </tr>
             <tr>
              <td>27</td>
              <td>2122</td>
              <td>Safna KS</td>
              <td>30/03/2000</td>
            </tr>
            <tr>
              <td>28</td>
              <td>2123</td>
              <td>Jeswin P Toji</td>
              <td>20/09/2001</td>
            </tr>
             <tr>
              <td>29</td>
              <td>2124</td>
              <td>Jeffin Joseph</td>
              <td>24/01/1997</td>
            </tr>
             <tr>
              <td>30</td>
              <td>2125</td>
              <td>Josmy Antony</td>
              <td>04/03/2002</td>
            </tr>
             <tr>
              <td>31</td>
              <td>2126</td>
              <td>Dona mol Raju</td>
              <td>20/04/2000</td>
            </tr>
              <tr>
              <td>32</td>
              <td>2127</td>
              <td>Savio Varghese</td>
              <td>02/05/1999</td>
            </tr>
            <tr>
              <td>33</td>
              <td>2128</td>
              <td>Josekutty Francis</td>
              <td>01/08/1995</td>
            </tr>
          
          </table>
        </v-flex>
</v-layout>
</v-flex>

<v-flex lg12 v-else-if="this.list === '2020-2022 Second Year'">
<v-layout wrap justify-center>
        <v-flex pt-5 xs12 lg9 class="bold" style="font-size:20px;color:#2c3e50">
  2020-2022 Second Year 
        </v-flex>
        <v-flex x12 lg9>
          <table>
            <tr>
              <th>S.No</th>
              <th>Adm.NO</th>
              <th>Name</th>
              <th>Dob</th>
            </tr>
            <tr>
              <td>1</td>
              <td>2063</td>
              <td>Roshna Joseph</td>
              <td>01/08/1998</td>
            </tr>
            <tr>
              <td>2</td>
              <td>2064</td>
              <td>Juval Marya Joseph</td>
              <td>09/05/1999</td>
            </tr>
            <tr>
              <td>3</td>
              <td>2065</td>
              <td>Amrutha mol Saji</td>
              <td>20/08/1999</td>
            </tr>
            <tr>
              <td>4</td>
              <td>2066</td>
              <td>Amina Siyad</td>
              <td>19/07/2001</td>
            </tr>
             <tr>
              <td>5</td>
              <td>2067</td>
              <td>Jinumol Reghunath</td>
              <td>08/07/1998</td>
            </tr>
             <tr>
              <td>6</td>
              <td>2068</td>
              <td>Simla Sunny</td>
              <td>28/12/1999</td>
            </tr>
             <tr>
              <td>7</td>
              <td>2069</td>
              <td>Tintu Kunjumon</td>
              <td>14/01/1998</td>
            </tr>
             <tr>
              <td>8</td>
              <td>2070</td>
              <td>Anna Maria Abraham</td>
              <td>12/08/2000</td>
            </tr>
             <tr>
              <td>9</td>
              <td>2071</td>
              <td>Ammu Thankappan.K</td>
              <td>17/09/1997</td>
            </tr>
             <tr>
              <td>10</td>
              <td>2072</td>
              <td>Sandra Thomas</td>
              <td>25/03/1998</td>
            </tr>
             <tr>
              <td>11</td>
              <td>2073</td>
              <td>Alen P mohandas</td>
              <td>17/03/1998</td>
            </tr>
             <tr>
              <td>12</td>
              <td>2074</td>
              <td>Akhila Biju</td>
              <td>20/04/1998  </td>
            </tr>
             <tr>
              <td>13</td>
              <td>2075</td>
              <td>Elsy Augustian</td>
              <td>21/12/2000 </td>
            </tr>
             <tr>
              <td>14</td>
              <td>2076</td>
              <td>Ashly Elsa Joseph</td>
              <td>14/08/2001</td>
            </tr>
             <tr>
              <td>15</td>
              <td>2077</td>
              <td>Arathy Binu</td>
              <td>22/05/2000</td>
            </tr>
             <tr>
              <td>16</td>
              <td>2078</td>
              <td>Renjumol Rajesh</td>
              <td>12/04/2001</td>
            </tr>
             <tr>
              <td>17</td>
              <td>2079</td>
              <td>Jomol Joy</td>
              <td>04/11/1998</td>
            </tr>
             <tr>
              <td>18</td>
              <td>2080</td>
              <td>Soumyamol K</td>
              <td>08/02/1995</td>
            </tr>
             <tr>
              <td>19</td>
              <td>2081</td>
              <td>Sona Abraham</td>
              <td>20/03/1997</td>
            </tr>
              <tr>
              <td>20</td>
              <td>2082</td>
              <td>Jomol KP</td>
              <td>12/08/2001</td>
            </tr>
             <tr>
              <td>21</td>
              <td>2083</td>
              <td>Albin T Reji</td>
              <td>09/01/1997</td>
            </tr>
            <tr>
              <td>22</td>
              <td>2084</td>
              <td>Sherin V Arun</td>
              <td>20/11/1997</td>
            </tr>
             <tr>
              <td>23</td>
              <td>2085</td>
              <td>Jithin P Johny	</td>
              <td>01/09/1996</td>
            </tr>
             <tr>
              <td>24</td>
              <td>2086</td>
              <td>Jomol Kochumon	</td>
              <td>27/12/2001</td>
            </tr>
            <tr>
              <td>25</td>
              <td>2087</td>
              <td>Agnus Peter</td>
              <td>21/04/2002</td>
            </tr>
             <tr>
              <td>26</td>
              <td>2088</td>
              <td>JobinRajan</td>
              <td>03/05/1997</td>
            </tr>
            <tr>
              <td>27</td>
              <td>2089</td>
              <td>Alan Varghese</td>
              <td>20/07/1996</td>
            </tr>
             <tr>
              <td>28</td>
              <td>2090</td>
              <td>Ancy Chacko</td>
              <td>30/05/1992</td>
            </tr>
             <tr>
              <td>29</td>
              <td>2091</td>
              <td>Sereena S</td>
              <td>16/06/2001</td>
            </tr>
             <tr>
              <td>30</td>
              <td>2092</td>
              <td>Sayan P Kurian</td>
              <td>03/02/2001</td>
            </tr>
              <tr>
              <td>31</td>
              <td>2093</td>
              <td>Merin Yohannan</td>
              <td>16/11/1991</td>
            </tr>
            <tr>
              <td>32</td>
              <td>2094</td>
              <td>Martin Thomas</td>
              <td>15/02/1998</td>
            </tr>
             <tr>
              <td>33</td>
              <td>2095</td>
              <td>Unnimaya Vinod</td>
              <td>14/12/1997</td>
            </tr>
          </table>
        </v-flex>
    </v-layout>
</v-flex>
  
<v-flex lg12 v-else-if="this.list === '2021-2023 New Students'">
    <v-layout wrap justify-center>
    <v-flex xs12 lg9 class="bold" style="font-size:20px;color:#2c3e50" pt-lg-6>
      2021-2023 New Students
  </v-flex>
  <v-flex x12 lg9>
      <div style="overflow-x: auto;">
          <table style="width: 100%; border-collapse: collapse;">
              <thead>
                  <tr>
                      <th style="min-width: 50px;">No.</th>
                      <th style="min-width: 100px;">Admission Date</th>
                      <th style="min-width: 120px;">Present Status</th>
                      <th style="min-width: 120px;">Class in which Studying</th>
                      <th style="min-width: 120px;">Year of Passing Out</th>
                      <th style="min-width: 150px;">University Roll No</th>
                      <th style="min-width: 200px;">Student Name</th>
                      <th style="min-width: 100px;">DOB</th>
                      <th style="min-width: 200px;">Father Name</th>
                      <th style="min-width: 200px;">Mother Name</th>
                      <th style="min-width: 100px;">Gender</th>
                      <th style="min-width: 100px;">Caste</th>
                      <th style="min-width: 150px;">Aadhaar No.</th>
                      <th style="min-width: 150px;">Mobile Number</th>
                      <th style="min-width: 200px;">Email ID</th>
                      <th style="min-width: 100px;">Is PH/Divyang</th>
                  </tr>
              </thead>
              <tbody>
                <tr>
    <td>1</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103432</td>
    <td>ANNIE THOMAS</td>
    <td>22/08/2002</td>
    <td>T J THOMAS</td>
    <td>REGIMOL MATHEW</td>
    <td>FEMALE</td>
    <td>CHRISTIAN</td>
    <td>674028786577</td>
    <td>9495710573</td>
    <td>aniethomasa1822@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>2</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103433</td>
    <td>ANJU THOMAS</td>
    <td>04/08/1998</td>
    <td>THOMAS MATHEW</td>
    <td>ALICE THOMAS</td>
    <td>FEMALE</td>
    <td>CHRISTIAN</td>
    <td>812149763207</td>
    <td>9495158025</td>
    <td>sranjuthomas98@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>3</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103434</td>
    <td>ANN PREETI MANOJ</td>
    <td>28/05/2003</td>
    <td>MANOJ JOSEPH</td>
    <td>JANE MANOJ</td>
    <td>FEMALE</td>
    <td>CHRISTIAN</td>
    <td>701796069546</td>
    <td>9605628221</td>
    <td>annpreetimanoj077@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>4</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103435</td>
    <td>ANU WILSON</td>
    <td>03/02/2001</td>
    <td>I WILSON</td>
    <td>FLORA WILSON</td>
    <td>FEMALE</td>
    <td>CHRISTIAN</td>
    <td>881601846407</td>
    <td>9744447201</td>
    <td>anuwilson2001@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>5</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103436</td>
    <td>ASTLINA ISRA FERNANDES</td>
    <td>04/07/2003</td>
    <td>MANUEL FERNANDES</td>
    <td>VALINDA FERNANDES</td>
    <td>FEMALE</td>
    <td>CHRISTIAN</td>
    <td>609009999287</td>
    <td>9496066085</td>
    <td>astlinaisra000@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>6</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103437</td>
    <td>AYONA GEORGE</td>
    <td>22/07/2003</td>
    <td>GEORGE ANTONY</td>
    <td>BINDU FRANCIS</td>
    <td>FEMALE</td>
    <td>CHRISTIAN</td>
    <td>848938600935</td>
    <td>9895937632</td>
    <td>ayonageorge94@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>7</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103438</td>
    <td>BIJINA JOSE</td>
    <td>17/03/2000</td>
    <td>JOSE K V</td>
    <td>BINI JOSE</td>
    <td>FEMALE</td>
    <td>CHRISTIAN</td>
    <td>770817350522</td>
    <td>9961699186</td>
    <td>bijinajose00@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>8</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103439</td>
    <td>BINTUMOL TOMY</td>
    <td>15/08/1994</td>
    <td>TOMY OUSEPH</td>
    <td>SALI TOMY</td>
    <td>FEMALE</td>
    <td>CHRISTIAN</td>
    <td>972983516540</td>
    <td>9947190635</td>
    <td>bintumoltomy94@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>9</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103440</td>
    <td>DIVYAMOL K JOY</td>
    <td>11/10/2000</td>
    <td>JOY K L</td>
    <td>JESY JOY</td>
    <td>FEMALE</td>
    <td>CHRISTIAN</td>
    <td>688362648084</td>
    <td>9526257906</td>
    <td>divyajoy104@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>10</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103441</td>
    <td>FAIROOS JAHAN</td>
    <td>04/08/2000</td>
    <td>BIJU</td>
    <td>RASHIDA</td>
    <td>FEMALE</td>
    <td>MUSLIM</td>
    <td>551275694890</td>
    <td>9744745767</td>
    <td>fairoosjahan25@gmail.com</td>
    <td>No</td>
</tr>

<tr>
    <td>11</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103442</td>
    <td>FEMIN THERESE B MAYA</td>
    <td>27/02/1999</td>
    <td>BENNY JOSEPH</td>
    <td>SHEEBA BENNY</td>
    <td>FEMALE</td>
    <td>CHRISTIAN</td>
    <td>229130516701</td>
    <td>9847082367</td>
    <td>femintheresebmaya@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>12</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103443</td>
    <td>GOPIKA SATHEESH</td>
    <td>27/05/2003</td>
    <td>SATHEESH G</td>
    <td>VINEETHA SATHEESH</td>
    <td>FEMALE</td>
    <td>HINDU</td>
    <td>941582785513</td>
    <td>9745716337</td>
    <td>gopikasatheesh755@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>13</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103444</td>
    <td>KRISHNA SUNIL</td>
    <td>13/02/2003</td>
    <td>P P SUNILKUMAR</td>
    <td>BINDHU SUNIL</td>
    <td>FEMALE</td>
    <td>HINDU</td>
    <td>306927782074</td>
    <td>9048234917</td>
    <td>krishnapsmukkulam@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>14</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103445</td>
    <td>NIKKITTA ANTONY</td>
    <td>26/08/2003</td>
    <td>ANTONY A</td>
    <td>VIJAYAKUMARI P K</td>
    <td>FEMALE</td>
    <td>CHRISTIAN</td>
    <td>651759638180</td>
    <td>9447840118</td>
    <td>nikkittaantony271@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>15</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103446</td>
    <td>REENU RAJAN</td>
    <td>14/12/1998</td>
    <td>RAJAN G</td>
    <td>MALLIKA RAJAN</td>
    <td>FEMALE</td>
    <td>CHRISTIAN</td>
    <td>878817156737</td>
    <td>9497244088</td>
    <td>reenu4lessons@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>16</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103448</td>
    <td>SHEMA M SHAJI</td>
    <td>04/08/2000</td>
    <td>SHAJI M J</td>
    <td>SULOCHANA SHAJI</td>
    <td>FEMALE</td>
    <td>CHRISTIAN</td>
    <td>526370150619</td>
    <td>9605060445</td>
    <td>shemamshaji23@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>17</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103449</td>
    <td>SONA MARIA VARKEY</td>
    <td>13/07/2003</td>
    <td>K J VARKEY</td>
    <td>LISSAMMA VARKEY</td>
    <td>FEMALE</td>
    <td>CHRISTIAN</td>
    <td>225633138352</td>
    <td>9495234274</td>
    <td>sonavarkey123@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>18</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103450</td>
    <td>TEENA BABU</td>
    <td>26/02/2001</td>
    <td>BABU P M</td>
    <td>SHANTY BABU</td>
    <td>FEMALE</td>
    <td>CHRISTIAN</td>
    <td>575002207838</td>
    <td>9847811456</td>
    <td>teenababu2001@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>19</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103451</td>
    <td>TREESA THOMAS</td>
    <td>13/01/2003</td>
    <td>RAJU XAVIER</td>
    <td>SUSAMMA BIJU</td>
    <td>FEMALE</td>
    <td>CHRISTIAN</td>
    <td>800437173032</td>
    <td>8281490093</td>
    <td>treesathomas050@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>20</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103452</td>
    <td>AKHILMON M SHAJI</td>
    <td>16/07/1996</td>
    <td>SHAJI M L</td>
    <td>LIGI SHAJI</td>
    <td>MALE</td>
    <td>CHRISTIAN</td>
    <td>243617659764</td>
    <td>8281812796</td>
    <td>akhilshajiakhilshaji80@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>21</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103453</td>
    <td>ALWIN JOSE</td>
    <td>18/08/2001</td>
    <td>JOSE JOSEPH</td>
    <td>ROSSAMMA JOSE</td>
    <td>MALE</td>
    <td>CHRISTIAN</td>
    <td>354827206528</td>
    <td>9048043697</td>
    <td>alwinjose0818@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>22</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103454</td>
    <td>ANAND MURALI</td>
    <td>24/09/1997</td>
    <td>P R MURALI</td>
    <td>PREETHA MURALI</td>
    <td>MALE</td>
    <td>HINDU</td>
    <td>813450920219</td>
    <td>9847783974</td>
    <td>anandmurali2015@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>23</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103455</td>
    <td>ANOOJ SATHEESH</td>
    <td>11/05/2000</td>
    <td>SATHEESH R</td>
    <td>MERCY SATHEESH</td>
    <td>MALE</td>
    <td>CHRISTIAN</td>
    <td>642519546136</td>
    <td>9747847043</td>
    <td>anoojanil7@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>24</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103456</td>
    <td>DEEPAK MARTIN</td>
    <td>13/11/2003</td>
    <td>MARTIN VARGHESE</td>
    <td>SINI MARTIN</td>
    <td>MALE</td>
    <td>CHRISTIAN</td>
    <td>560556676960</td>
    <td>9497320408</td>
    <td>deepakmartinampattu@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>25</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103457</td>
    <td>DIPIN JOSE</td>
    <td>16/01/1996</td>
    <td>JOSE GEORGE</td>
    <td>SEENA JOSE</td>
    <td>MALE</td>
    <td>CHRISTIAN</td>
    <td>269119981008</td>
    <td>8921683450</td>
    <td>dipinvvv@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>26</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103458</td>
    <td>JOEL JOBY</td>
    <td>19/05/2003</td>
    <td>JOBY THOMAS</td>
    <td>LIGIMOL JOSEPH K</td>
    <td>MALE</td>
    <td>CHRISTIAN</td>
    <td>559113182860</td>
    <td>9947064863</td>
    <td>joeljoby469@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>27</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103459</td>
    <td>KIRAN RAJAN</td>
    <td>09/08/1999</td>
    <td>RAJAN C</td>
    <td>SHOBHANA RAJAN</td>
    <td>MALE</td>
    <td>HINDU</td>
    <td>500951339895</td>
    <td>9633653314</td>
    <td>kiranrajanwrite24@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>28</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103460</td>
    <td>NIRMAL K S</td>
    <td>22/07/1998</td>
    <td>STANLY K J</td>
    <td>LISSY STANLY</td>
    <td>MALE</td>
    <td>CHRISTIAN</td>
    <td>577991203256</td>
    <td>9446733765</td>
    <td>kadukuttiyilnirmal@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>29</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103461</td>
    <td>NOYAL JOSEPH</td>
    <td>05/07/2003</td>
    <td>JOSEPH K V</td>
    <td>LISSY V K</td>
    <td>MALE</td>
    <td>CHRISTIAN</td>
    <td>464660862163</td>
    <td>9446766979</td>
    <td>noyaljoseph7777@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>30</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103462</td>
    <td>RINTO REJI</td>
    <td>16/04/2000</td>
    <td>REJI S</td>
    <td>RINCY REJI</td>
    <td>MALE</td>
    <td>CHRISTIAN</td>
    <td>436298501098</td>
    <td>9947315007</td>
    <td>rintoreji06091@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>31</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103463</td>
    <td>SHIBIN A S</td>
    <td>10/07/1997</td>
    <td>SEBASTIAN A J</td>
    <td>BINDHU SEBASTIAN</td>
    <td>MALE</td>
    <td>CHRISTIAN</td>
    <td>707181883577</td>
    <td>9846036720</td>
    <td>shibinas1819@gmail.com</td>
    <td>No</td>
</tr>
<tr>
    <td>32</td>
    <td>12/10/2021</td>
    <td>Pass Out</td>
    <td>I YEAR</td>
    <td>2021</td>
    <td>2103464</td>
    <td>TONY GEORGE</td>
    <td>10/05/2003</td>
    <td>SHAILAC GEORGE</td>
    <td>SHARLY GEORGE</td>
    <td>MALE</td>
    <td>CHRISTIAN</td>
    <td>877435285594</td>
    <td>9961777683</td>
    <td>tonygeorge786@gmail.com</td>
    <td>No</td>
</tr>


                  
                  <!-- Add more rows as needed -->
              </tbody>
          </table>
      </div>
  </v-flex>
  </v-layout>
</v-flex>
  
<v-flex lg12 v-else-if="this.list === '2022-2024 First Year'">
  <v-layout wrap justify-center>
    <v-flex xs12 lg9 class="bold" style="font-size:20px;color:#2c3e50" pt-lg-6>
      2022-2024 First Year
    </v-flex>
    <v-flex x12 lg9>
      <div style="overflow-x: auto;">
        <table style="width: 100%; border-collapse: collapse;">
          <thead>
            <tr>
              <th style="min-width: 50px;">No.</th>
              <th style="min-width: 100px;">Admission Date</th>
              <th style="min-width: 120px;">Present Status</th>
              <th style="min-width: 120px;">Class in which Studying</th>
              <th style="min-width: 120px;">Year of Passing Out</th>
              <th style="min-width: 150px;">University Roll No</th>
              <th style="min-width: 200px;">Student Name</th>
              <th style="min-width: 100px;">DOB</th>
              <th style="min-width: 200px;">Father Name</th>
              <th style="min-width: 200px;">Mother Name</th>
              <th style="min-width: 100px;">Gender</th>
              <th style="min-width: 100px;">Caste</th>
              <th style="min-width: 150px;">Aadhaar No.</th>
              <th style="min-width: 150px;">Mobile Number</th>
              <th style="min-width: 200px;">Email ID</th>
              <th style="min-width: 100px;">Is PH/Divyang</th>
            </tr>
          </thead>
          <tbody>
            <tr>
      <td>1</td>
      <td>09/01/2022</td>
      <td>Pass Out</td>
      <td>I YEAR</td>
      <td>2024</td>
      <td>2203651</td>
      <td>AARSHA MARIA JOSEPH</td>
      <td>06/02/2001</td>
      <td>C M JOSEPH</td>
      <td>PUSHPAMOL V C</td>
      <td>FEMALE</td>
      <td>CHRISTIAN</td>
      <td>335933743006</td>
      <td>9496013973</td>
      <td>aarshamaria2001@gmail.com</td>
      <td>No</td>
    </tr>
    <tr>
      <td>2</td>
      <td>09/01/2022</td>
      <td>Pass Out</td>
      <td>I YEAR</td>
      <td>2024</td>
      <td>2203652</td>
      <td>AISWARYA LAKSHMI K S</td>
      <td>02/05/2003</td>
      <td>SANUMON K K</td>
      <td>SINDHUMOL K K</td>
      <td>FEMALE</td>
      <td>HINDU</td>
      <td>879972416427</td>
      <td>9496724994</td>
      <td>sindhusanu2003@gmail.com</td>
      <td>No</td>
    </tr>
    <tr>
      <td>3</td>
      <td>09/01/2022</td>
      <td>Pass Out</td>
      <td>I YEAR</td>
      <td>2024</td>
      <td>2203653</td>
      <td>ALEENA SHIBU</td>
      <td>23/03/2004</td>
      <td>SHIBU P J</td>
      <td>MINI SHIBU</td>
      <td>FEMALE</td>
      <td>CHRISTIAN</td>
      <td>920674266930</td>
      <td>9947307403</td>
      <td>aleenashibu90@gmail.com</td>
      <td>No</td>
    </tr>
    <tr>
      <td>4</td>
      <td>09/01/2022</td>
      <td>Pass Out</td>
      <td>I YEAR</td>
      <td>2024</td>
      <td>2203654</td>
      <td>AMALA P MATHEW</td>
      <td>28/05/2001</td>
      <td>MATHEW ABRAHAM</td>
      <td>LIGIMOL MATHEW</td>
      <td>FEMALE</td>
      <td>CHRISTIAN</td>
      <td>699674068378</td>
      <td>9074244785</td>
      <td>amalapmathew2001@gmail.com</td>
      <td>No</td>
    </tr>
    <tr>
      <td>5</td>
      <td>09/01/2022</td>
      <td>Pass Out</td>
      <td>I YEAR</td>
      <td>2024</td>
      <td>2203655</td>
      <td>ANGEL MARIA UNNI</td>
      <td>24/04/2004</td>
      <td>UNNI K P</td>
      <td>RENJANI K RAJU</td>
      <td>FEMALE</td>
      <td>CHRISTIAN</td>
      <td>813314503105</td>
      <td>9562814381</td>
      <td>angelmariaunni478@gmail.com</td>
      <td>No</td>
    </tr>
    <tr>
      <td>6</td>
      <td>09/01/2022</td>
      <td>Pass Out</td>
      <td>I YEAR</td>
      <td>2024</td>
      <td>2203656</td>
      <td>ANJALI JOSHY</td>
      <td>08/06/2004</td>
      <td>YOSUVA</td>
      <td>BIJALY</td>
      <td>FEMALE</td>
      <td>CHRISTIAN</td>
      <td>235062144113</td>
      <td>9562716068</td>
      <td>anjalijoshy334@gmail.com</td>
      <td>No</td>
    </tr>
    <tr>
      <td>7</td>
      <td>09/01/2022</td>
      <td>Pass Out</td>
      <td>I YEAR</td>
      <td>2024</td>
      <td>2203657</td>
      <td>ANNA AIRIN THOMAS</td>
      <td>22/11/2001</td>
      <td>THOMAS ANTONY</td>
      <td>RUBY THOMAS</td>
      <td>FEMALE</td>
      <td>CHRISTIAN</td>
      <td>323438385808</td>
      <td>9847905167</td>
      <td>annaairin5167@gmail.com</td>
      <td>No</td>
    </tr>
    <tr>
      <td>8</td>
      <td>09/01/2022</td>
      <td>Pass Out</td>
      <td>I YEAR</td>
      <td>2024</td>
      <td>2203658</td>
      <td>ARUNIMA BIJU</td>
      <td>20/09/2004</td>
      <td>BIJUMON M B</td>
      <td>SHYLAMOL M K</td>
      <td>FEMALE</td>
      <td>HINDU</td>
      <td>808884364329</td>
      <td>9961263557</td>
      <td>arunimabijumb@gmail.com</td>
      <td>No</td>
    </tr>
    <tr>
      <td>9</td>
      <td>09/01/2022</td>
      <td>Pass Out</td>
      <td>I YEAR</td>
      <td>2024</td>
      <td>2203659</td>
      <td>ASWATHY M K</td>
      <td>10/08/2001</td>
      <td>KUNJUMON M K</td>
      <td>SULOCHANA E V</td>
      <td>FEMALE</td>
      <td>HINDU</td>
      <td>800884364329</td>
      <td>7510171645</td>
      <td>aswathymk177@gmail.com</td>
      <td>No</td>
    </tr>
    <tr>
      <td>10</td>
      <td>09/01/2022</td>
      <td>Pass Out</td>
      <td>I YEAR</td>
      <td>2024</td>
      <td>2203660</td>
      <td>BEDZY BENNY</td>
      <td>23/11/2001</td>
      <td>BENNY JOSEPH</td>
      <td>SHINY BENNY</td>
      <td>FEMALE</td>
      <td>CHRISTIAN</td>
      <td>609791223312</td>
      <td>9562801957</td>
      <td>betzybenny3302@gmail.com</td>
      <td>No</td>
    </tr>

    <tr>
  <td>11</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203661</td>
  <td>BHAGYALAKSHMI K S</td>
  <td>02/05/2003</td>
  <td>SANUMON K K</td>
  <td>SINDHUMOL K K</td>
  <td>FEMALE</td>
  <td>CHRISTIAN</td>
  <td>962760781040</td>
  <td>9496724994</td>
  <td>bhagyaaiswarya@gmail.com</td>
  <td>No</td>
</tr>
<tr>
  <td>12</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203662</td>
  <td>BISMI BIJU</td>
  <td>02/10/2004</td>
  <td>BIJU THOMAS</td>
  <td>BINDHU BIJU</td>
  <td>FEMALE</td>
  <td>CHRISTIAN</td>
  <td>253384054289</td>
  <td>9744193519</td>
  <td>bismibiju04@gmail.com</td>
  <td>No</td>
</tr>
<tr>
  <td>13</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203663</td>
  <td>DELLA BABU</td>
  <td>28/02/2001</td>
  <td>BABU JOSEPH</td>
  <td>BINDHU BABU</td>
  <td>FEMALE</td>
  <td>CHRISTIAN</td>
  <td>178493084567</td>
  <td>9654781234</td>
  <td>della.babu@gmail.com</td>
  <td>No</td>
</tr>
<tr>
  <td>14</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203664</td>
  <td>DEVANANDHANA K S</td>
  <td>07/06/2004</td>
  <td>SALVIN K S</td>
  <td>SINDHU SALVIN</td>
  <td>FEMALE</td>
  <td>HINDU</td>
  <td>685139877642</td>
  <td>9496800493</td>
  <td>devanandanaks1@gmail.com</td>
  <td>No</td>
</tr>
<tr>
  <td>15</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203665</td>
  <td>JINCYMOL JOSEPH</td>
  <td>30/08/2001</td>
  <td>OUSEPH M P</td>
  <td>LEELAMMA OUSEPH</td>
  <td>FEMALE</td>
  <td>CHRISTIAN</td>
  <td>951154258935</td>
  <td>9605831522</td>
  <td>jincymoljosephjincy@gmail.com</td>
  <td>No</td>
</tr>
<tr>
  <td>16</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203666</td>
  <td>JOSYMOL J</td>
  <td>04/08/2001</td>
  <td>JOSEPH</td>
  <td>VINCY J</td>
  <td>FEMALE</td>
  <td>CHRISTIAN</td>
  <td>321123121970</td>
  <td>9567917185</td>
  <td>josymoljjosymolj15@gmail.com</td>
  <td>No</td>
</tr>
<tr>
  <td>17</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203667</td>
  <td>KRISHNAPRIYA P J</td>
  <td>25/07/2001</td>
  <td>JAYAPRAKASH</td>
  <td>BINDU JAYAPRAKASH</td>
  <td>FEMALE</td>
  <td>HINDU</td>
  <td>383992136922</td>
  <td>7558087289</td>
  <td>krishnapriyapj2001@gmail.com</td>
  <td>No</td>
</tr>
<tr>
  <td>18</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203668</td>
  <td>LITHUMOL C</td>
  <td>09/05/2004</td>
  <td>CHINNAPPAN</td>
  <td>SHEEJA CHINNAPPAN</td>
  <td>FEMALE</td>
  <td>CHRISTIAN</td>
  <td>222605137759</td>
  <td>8281829181</td>
  <td>lithumol005@gmail.com</td>
  <td>No</td>
</tr>
<tr>
  <td>19</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203669</td>
  <td>MARIA TRESA ANTONY</td>
  <td>14/02/2002</td>
  <td>ANTONY K C</td>
  <td>SELIN ANTONY</td>
  <td>FEMALE</td>
  <td>CHRISTIAN</td>
  <td>831658710446</td>
  <td>9526250795</td>
  <td>mariyatreesaantony@gmail.com</td>
  <td>No</td>
</tr>
<tr>
  <td>20</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203670</td>
  <td>MARYAM NOURIN</td>
  <td>22/07/2004</td>
  <td>NIYAS P A</td>
  <td>THAHIRA BEEGUM</td>
  <td>FEMALE</td>
  <td>MUSLIM</td>
  <td>831688710446</td>
  <td>8547271787</td>
  <td>nourinmaryam168@gmail.com</td>
  <td>No</td>
</tr>
<tr>
  <td>21</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203671</td>
  <td>MEERA P REJI</td>
  <td>20/09/1998</td>
  <td>REJIMON P C</td>
  <td>SINDHU REJI</td>
  <td>FEMALE</td>
  <td>HINDU</td>
  <td>209252186309</td>
  <td>9447343292</td>
  <td>meerapnmana@gmail.com</td>
  <td>No</td>
</tr>
<tr>
  <td>22</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203672</td>
  <td>NEETHU SEBASTIAN</td>
  <td>18/01/1999</td>
  <td>SEBASTIAN PETER</td>
  <td>CICILY PETER</td>
  <td>FEMALE</td>
  <td>CHRISTIAN</td>
  <td>840837956694</td>
  <td>8281811845</td>
  <td>maryjanetneethusebastian@gmail.com</td>
  <td>No</td>
</tr>
<tr>
  <td>23</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203673</td>
  <td>RIYA VARGHESE</td>
  <td>30/06/2003</td>
  <td>VARGHESE M J</td>
  <td>REJI MATHEW</td>
  <td>FEMALE</td>
  <td>CHRISTIAN</td>
  <td>693982690158</td>
  <td>9744399750</td>
  <td>riyavarghese2003@gmail.com</td>
  <td>No</td>
</tr>

<tr>
  <td>24</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203674</td>
  <td>ROSE TREASA PAUL</td>
  <td>16/05/2001</td>
  <td>PAUL P C</td>
  <td>JESSY PAUL</td>
  <td>FEMALE</td>
  <td>CHRISTIAN</td>
  <td>400708595893</td>
  <td>8589856749</td>
  <td>rosetreasapaul8@gmail.com</td>
  <td>No</td>
</tr>
<tr>
  <td>25</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203675</td>
  <td>SANIYA P J</td>
  <td>19/07/2004</td>
  <td>SANTHOSH JOSEPH</td>
  <td>LINI SANTHOSH</td>
  <td>FEMALE</td>
  <td>CHRISTIAN</td>
  <td>992160478180</td>
  <td>9496027126</td>
  <td>pjsaniya1904@gmail.com</td>
  <td>No</td>
</tr>
<tr>
  <td>26</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203676</td>
  <td>SHERIMOL SHAJI</td>
  <td>15/06/2000</td>
  <td>SHAJI O G</td>
  <td>REENA SHAJI</td>
  <td>FEMALE</td>
  <td>CHRISTIAN</td>
  <td>418125756116</td>
  <td>7994386253</td>
  <td>sherimolshaji5@gmail.com</td>
  <td>No</td>
</tr>
<tr>
  <td>27</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203677</td>
  <td>AKSHAY THOMAS</td>
  <td>29/03/2002</td>
  <td>THOMAS M J</td>
  <td>SALI THOMAS</td>
  <td>MALE</td>
  <td>CHRISTIAN</td>
  <td>519263131768</td>
  <td>9895739695</td>
  <td>akshaymt20@gmail.com</td>
  <td>No</td>
</tr>
<tr>
  <td>28</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203678</td>
  <td>ALEN TOM SHIBU</td>
  <td>12/08/2000</td>
  <td>SHIBU THOMAS</td>
  <td>SHEEJA P JOHN</td>
  <td>MALE</td>
  <td>CHRISTIAN</td>
  <td>302780766812</td>
  <td>9961242681</td>
  <td>alentomshibualen@gmail.com</td>
  <td>No</td>
</tr>
<tr>
  <td>29</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203679</td>
  <td>BIBIN GEORGE</td>
  <td>13/12/1998</td>
  <td>JOHNSON G</td>
  <td>JAMEELA</td>
  <td>MALE</td>
  <td>CHRISTIAN</td>
  <td>486315991769</td>
  <td>8943914447</td>
  <td>bibing82100@gmail.com</td>
  <td>No</td>
</tr>
<tr>
  <td>30</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203680</td>
  <td>JENTO MATHEW</td>
  <td>23/03/2003</td>
  <td>M C MATHEW</td>
  <td>ROSILY MATHEW</td>
  <td>MALE</td>
  <td>CHRISTIAN</td>
  <td>989988098867</td>
  <td>8157987825</td>
  <td>jentomathew24@gmail.com</td>
  <td>No</td>
</tr>
<tr>
  <td>31</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203681</td>
  <td>JOSEPH JOSHY</td>
  <td>01/10/2004</td>
  <td>JOSHY K JOSEPH</td>
  <td>BINDHU JOSHY</td>
  <td>MALE</td>
  <td>CHRISTIAN</td>
  <td>677578410910</td>
  <td>8590323855</td>
  <td>josephjoshybindu@gmail.com</td>
  <td>No</td>
</tr>
<tr>
  <td>32</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203682</td>
  <td>SHAIJU JOSEPH</td>
  <td>24/02/2005</td>
  <td>JOSEPH DEVASIA</td>
  <td>JULIE JOSEPH</td>
  <td>MALE</td>
  <td>CHRISTIAN</td>
  <td>737034187313</td>
  <td>9656340352</td>
  <td>kochupurackalshaiju@gmail.com</td>
  <td>No</td>
</tr>
<tr>
  <td>33</td>
  <td>09/01/2022</td>
  <td>Pass Out</td>
  <td>I YEAR</td>
  <td>2024</td>
  <td>2203683</td>
  <td>SHEBIN SHAJI</td>
  <td>17/05/2001</td>
  <td>SHAJI VARGHESE</td>
  <td>OMANA SHAJI</td>
  <td>MALE</td>
  <td>CHRISTIAN</td>
  <td>715091457914</td>
  <td>9605796459</td>
  <td>shebinshaji351@gmail.com</td>
  <td>No</td>
</tr>

          </tbody>
        </table>
      </div>
    </v-flex>
  </v-layout>
</v-flex>

  
  
  
      </v-layout>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        // Array of button data
        list: this.$route.query.list,
        buttons: [
          { label: "2020-2022 First Year", description: "This is the first card." },
          { label: "2020-2022 Second Year", description: "This is the second card." },
          { label: "2021-2023 New Students", description: "This is the third card." },
          // { label: "Card 4", description: "This is the fourth card." },
        ],
      };
    },
    methods: {
      // Handle button click
      onButtonClick(button) {
        console.log("Button clicked:", button.value);
      },
    },
  };
  </script>
  
  <style>
  table {
    font-family: pbold;
    border-collapse: collapse;
    width: 100%;
  }
  
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
    font-family: pregular;
  }
  tr {
    font-family: pregular;
  }
  .LH {
    line-height: 30px;
  }
  </style>